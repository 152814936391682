import { WORDS } from '../constants/wordlist'
import { VALID_GUESSES } from '../constants/validGuesses'

export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(word.toLowerCase()) ||
    VALID_GUESSES.includes(word.toLowerCase())
  )
}

export const isWinningWord = (word: string) => {
  return solution === word
}

export const getWordOfDay = () => {
  // Feb 8, 2022 Game Epoch
  const epochMs = new Date('February 8, 2022 00:00:00').valueOf()
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs) / msInDay)
  const nextday = (index + 1) * msInDay + epochMs
  const solution = WORDS[index % WORDS.length].toUpperCase()
  var sum = 0
  for (let i = 0; i < solution.length; ++i) {
    sum += solution.charCodeAt(i);
  }
  const color = (sum % 2 === 0) ? "blue" : "pink";

  return {
    solution: solution,
    solutionIndex: index + 1,
    tomorrow: nextday,
    color: color,
  }
}

export const { solution, solutionIndex, tomorrow, color } = getWordOfDay()
