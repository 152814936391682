import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { TwitterFollowButton } from 'react-twitter-embed'


type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the word in 8 tries. After each guess, the color of the tiles will
        change to show how close your guess was to the word. The meaning of the
        colors will change every day and that is what makes Hardle harder than
        Wordle! <br/><br/>For example, if today BLUE is the chosen color, then
        BLUE means the letter is in the word and in the correct spot whereas
        PINK means the letter is in the word but in the wrong spot. Things are
        the opposite way on PINK days!
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="W" status="forcecorrect" />
        <Cell value="E" />
        <Cell value="A" />
        <Cell value="R" />
        <Cell value="Y" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter W is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />
        <Cell value="I" />
        <Cell value="L" status="forcepresent" />
        <Cell value="O" />
        <Cell value="T" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter L is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="V" />
        <Cell value="A" />
        <Cell value="G" />
        <Cell value="U" status="absent" />
        <Cell value="E" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter U is not in the word in any spot.
      </p>
      <br/>
      <div className="flex justify-center">
        <TwitterFollowButton
          screenName={'vigneshv_psg'}
          options={{
            'showCount': 'false',
            'showScreenName': 'false',
            'size': 'large'
          }}
        />
      </div>
    </BaseModal>
  )
}
