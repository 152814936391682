import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'

export const doShare = (guesses: string[], lost: boolean) => {
  const text = `Hardle 🟦🟪 #${solutionIndex} ${lost ? "X" : guesses.length}/8\n\n${generateEmojiGrid(guesses)}\n\nhttps://hardle.herokuapp.com`
  if (navigator.share) {
    navigator.share({text})
    return true
  } else {
    navigator.clipboard.writeText(text)
    return false
  }
}

export const generateEmojiGrid = (guesses: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      return guess
        .split('')
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟩'
            case 'present':
              return '🟨'
            default:
              return '⬜'
          }
        })
        .join('')
    })
    .join('\n')
}
